$(document).ready(function(){

	/* Project pages */
	var player = Plyr.setup('.player');
	$('.player').bind('play', function() {
		activated = this;
		$('.player').each(function() {
			if(this != activated) { this.pause(); }
		});
	});

	/* Work: Sort */
	$(".sort-nav .filters select").on("change", function(e){
		e.preventDefault();
		var sortType = $(this).data("sort");
		var sortValue = this.value;		
		var currentUrl = $(location).attr('href').split("&");
		window.location = currentUrl[0] + "&" + sortType + "=" + sortValue;
	});

	/* Navigation */
	$(".toggle-navigation").on("click", function(e){
		e.preventDefault();

		if ($("body").hasClass("search-active")){
			$("body").removeClass("nav-active").removeClass("search-active");
		} else if ($("body").hasClass("nav-active")){
			$("body").toggleClass("nav-active");
		} else {
			$("body").toggleClass("nav-active");
		}
	});


	/* Search */
	$(".open-search").on("click", function(e){
		e.preventDefault();
		$("body").addClass("search-active");
		$("#search input").focus();
	});
	$(".close-search").on("click", function(e){
		e.preventDefault();
		$("body").removeClass("search-active");
	});


	/* Scroll to top */
	$(".to-top").on("click", function(e){
		e.preventDefault();
		$("html, body").animate({scrollTop: 0}, 1000);
	})

	/* Scroll to link */
	$(".scroll-links a").on("click", function(e){
		e.preventDefault();
		var l = $(this).attr('href').split("#")[1];
		var o = $("[id=" + l + "]");
		$("html, body").animate({scrollTop: o.offset().top}, 1000);
	})

	/* Projects Infinite Scroll */
	let $container = $('.projects-set').infiniteScroll({
	  // options
	  path: '.pagination-next',
	  append: '.tile',
	  history: false,
		hideNav: '.pagination',
		status: '.page-load-status',
	});

	/* Force second parsing of images do to known 3-year old Safari bug */
	$container.on( 'append.infiniteScroll', function( event, response, path, items ) {
	  $( items ).find('img[srcset]').each( function( i, img ) {
	    img.outerHTML = img.outerHTML;
	  });
	});
});
